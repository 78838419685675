import React, { useEffect, useRef, useState } from 'react'
import Vimeo from '@u-wave/react-vimeo'

import Play from '../images/play.inline.svg'
import Pause from '../images/pause.inline.svg'

export function VimeoPlayer({
    vimeoId,
    thumbnail,
    figcaption,
}: {
    vimeoId: string
    thumbnail: string
    figcaption?: string | JSX.Element
}) {
    const videoPreview = useRef(false)
    const [playerIsReady, setPlayerIsReady] = useState(false)
    const [activeVideo, setActiveVideo] = useState('')
    const [paused, setPaused] = useState(true)
    const [volume, setVolume] = useState(0)
    const figureElement = useRef()

    const [videoId, previewId] = vimeoId.split(' | ')

    useEffect(() => {
        if (previewId) {
            setActiveVideo(previewId)
            videoPreview.current = true

            if (typeof document !== `undefined`) {
                document.addEventListener('scroll', handleScroll)
            }
        } else {
            setActiveVideo(videoId)
        }
    }, [])

    function handleScroll() {
        if (videoPreview.current) {
            if (isElementInViewport(figureElement.current)) setPaused(false)
            else setPaused(true)
        }
    }

    function isElementInViewport(el: any) {
        let top = el.offsetTop - 100
        let left = el.offsetLeft
        let width = el.offsetWidth
        let height = el.offsetHeight

        while (el.offsetParent) {
            el = el.offsetParent
            top += el.offsetTop
            left += el.offsetLeft
        }

        return (
            top < window.pageYOffset + window.innerHeight &&
            left < window.pageXOffset + window.innerWidth &&
            top + height > window.pageYOffset &&
            left + width > window.pageXOffset
        )
    }

    function playPreview() {
        if (videoPreview.current) setPaused(false)
    }

    function togglePlay() {
        if (videoPreview.current) {
            setActiveVideo(videoId)
            setVolume(1)
            videoPreview.current = false
        } else {
            setVolume(1)
            setPaused((p) => !p)
        }
    }

    return (
        <>
            <figure
                ref={figureElement}
                className="video vimeo"
                style={{ backgroundImage: `url(${thumbnail ?? ''})` }}
            >
                {videoPreview.current && playerIsReady && (
                    <button
                        className="button play-button show"
                        onClick={togglePlay}
                    >
                        <Play />
                    </button>
                )}
                {!videoPreview.current && playerIsReady && (
                    <button
                        className={
                            paused
                                ? 'button play-button show'
                                : 'button play-button'
                        }
                        onClick={togglePlay}
                    >
                        {!paused ? <Pause /> : <Play />}
                    </button>
                )}
                {/**
                 * The video preview should not show controls.
                 * Because the vimeo component controls property does not
                 * refresh the controls ui, we have to use a second vimeo
                 * compontent for the actual video.
                 */}
                {activeVideo.length > 0 && videoPreview.current && (
                    <Vimeo
                        dnt={true}
                        onReady={(e) => setPlayerIsReady(true)}
                        video={activeVideo}
                        autoplay={videoPreview.current}
                        background={videoPreview.current}
                        muted={true}
                        showByline={false}
                        showTitle={false}
                        color="#eceded"
                        volume={volume}
                        paused={paused}
                        loop={true}
                        onLoaded={playPreview}
                        transparent={false}
                        onPlay={() => setPaused(false)}
                        onPause={() => setPaused(true)}
                        controls={false}
                    />
                )}
                {activeVideo.length > 0 && !videoPreview.current && (
                    <Vimeo
                        dnt={true}
                        onReady={(e) => setPlayerIsReady(true)}
                        video={activeVideo}
                        autoplay={!!previewId}
                        background={false}
                        muted={true}
                        showByline={false}
                        showTitle={false}
                        color="#eceded"
                        volume={volume}
                        paused={paused}
                        loop={true}
                        transparent={false}
                        onPlay={() => setPaused(false)}
                        onPause={() => setPaused(true)}
                        controls={true}
                    />
                )}
            </figure>
            {figcaption && <div className="figcaption">{figcaption}</div>}
        </>
    )
}
