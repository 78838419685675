import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link, StaticQuery, graphql } from 'gatsby'
import {
    CarouselProvider,
    Slider,
    Slide,
    DotGroup,
    ButtonFirst,
} from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'

import { isType } from '../../utils/typecheck'
import WideArrowLink from '../simple/wideArrowLink'
import string_to_slug from '../../utils/slug'
import ArticlePreview from '../simple/articlePreview'

export default function NextArticles({
    heading = '',
    slugs = [],
    articlesCount = 3,
}) {
    let [breakpoint, setBreakpoint] = useState('')
    let [totalSlides, setTotalSlides] = useState(3)
    let [twoRows, setTwoRows] = useState(false)

    function handleViewportChange() {
        // Set breakpoint
        window.innerWidth < 640
            ? setBreakpoint('small')
            : window.innerWidth < 1422
            ? setBreakpoint('medium')
            : setBreakpoint('large')

        // Change showed slides based on breakpoint
        if (
            breakpoint === 'small' ||
            breakpoint === 'medium' ||
            articlesCount <= 3
        ) {
            setTotalSlides(articlesCount)
            setTwoRows(false)
        } else if (articlesCount >= 3) {
            setTotalSlides(3)
            setTwoRows(true)
        }

        // Scroll to first slide to avoid whitespace-slide on desktop
        // @ts-ignore
        document.querySelector('.carousel__first-button').click()
    }

    useEffect(() => {
        handleViewportChange()
    })

    if (typeof window !== 'undefined') {
        window.onresize = handleViewportChange
    }

    function createArticles(index, item) {
        let magazineItem = {
            index: 0,
            link: '',
            image: '',
            category: '',
            title: '',
            text: '',
        }
        magazineItem.index = index
        magazineItem.link = `/${item.nodes[0].table.toLowerCase()}/${
            item.nodes[0].data.Slug
        }`
        magazineItem.category =
            item.nodes[0].table === 'Projekte' ? 'Projekt' : 'Artikel'
        item.nodes.map((textItem) => {
            switch (true) {
                case isType(textItem.data.Type, 'H1'):
                    magazineItem.title = textItem.data.Content
                case isType(textItem.data.Type, 'Teasertext'):
                    magazineItem.text = textItem.data.Content
                case isType(textItem.data.Type, 'Titelbild'):
                    magazineItem.image = textItem.data.Attachments?.[0].src
            }
        })
        return (
            <Slide index={magazineItem.index} key={magazineItem.index}>
                <ArticlePreview article={magazineItem} />
            </Slide>
        )
    }

    return (
        <NextArticlesContainer className="next-articles" twoRows={twoRows}>
            <div className="kicker">Magazin</div>
            {heading && <h2 className="h1">{heading}</h2>}
            <div className="articles">
                <CarouselProvider
                    naturalSlideWidth={100}
                    naturalSlideHeight={120}
                    totalSlides={totalSlides}
                    visibleSlides={
                        breakpoint === 'small'
                            ? 1
                            : breakpoint === 'medium'
                            ? 2
                            : 3
                    }
                    step={1}
                    dragEnabled={
                        breakpoint === 'small'
                            ? true
                            : breakpoint === 'medium'
                            ? true
                            : false
                    }
                >
                    <Slider>
                        <StaticQuery
                            query={graphql`
                                query nextArticles {
                                    allAirtable(
                                        filter: {
                                            table: {
                                                in: ["Projekte", "Artikel"]
                                            }
                                        }
                                        sort: { order: ASC, fields: rowIndex }
                                    ) {
                                        group(field: data___Slug) {
                                            nodes {
                                                table
                                                data {
                                                    Type
                                                    Content
                                                    Attachments {
                                                        src
                                                    }
                                                    Slug
                                                }
                                            }
                                        }
                                    }
                                }
                            `}
                            render={(data) =>
                                slugs.length > 0
                                    ? slugs.map((slug, index) => {
                                          return data.allAirtable.group.map(
                                              (item) => {
                                                  if (
                                                      item.nodes[0].data
                                                          .Slug[0] ===
                                                      string_to_slug(slug)
                                                  ) {
                                                      if (
                                                          index < articlesCount
                                                      ) {
                                                          return createArticles(
                                                              index,
                                                              item
                                                          )
                                                      }
                                                  }
                                              }
                                          )
                                      })
                                    : data.allAirtable.group.map(
                                          (item, index) => {
                                              if (index < articlesCount) {
                                                  return createArticles(
                                                      index,
                                                      item
                                                  )
                                              }
                                          }
                                      )
                            }
                        />
                    </Slider>
                    <DotGroup />
                    <ButtonFirst>Dont show me!</ButtonFirst>
                </CarouselProvider>
            </div>
            <WideArrowLink
                onWhiteBackground={true}
                text="Das ganze Magazin erleben | /magazin"
            />
        </NextArticlesContainer>
    )
}

const NextArticlesContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(25, 1fr);
    padding: 2.5rem 0;
    color: ${(props) => props.theme.colorPrimary};
    position: relative;
    max-width: 100vw;
    overflow-x: hidden;

    @media (min-width: 1422px) {
        overflow-x: visible;
    }

    @media (min-width: ${(props) => props.theme.md}) {
        padding: 5.5rem 0 8rem 0;
    }

    &::before {
        content: '';
        position: absolute;
        width: 100vw;
        height: 100%;
        background-color: ${(props) => props.theme.colorGrayLight};
        z-index: -1;
        grid-column: 2 / 26;
        .index & {
            right: 0;
        }
    }

    .carousel__slider {
        ${(props) =>
            props.twoRows ? 'max-height: 44rem;' : 'max-height: 29rem;'}

        @media (min-width: ${(props) => props.theme.sm}) {
            min-height: 24rem;
        }
    }

    .articles {
        margin-top: 1rem;
        grid-column: 3 / -2;

        @media (min-width: ${(props) => props.theme.md}) {
            margin-top: 3rem;
            grid-column: 5 / -3;
        }

        @media (min-width: ${(props) => props.theme.lg}) {
            grid-column: 6 / -3;
        }

        a.article-preview {
            margin-right: 1rem;
            display: block;
            color: ${(props) => props.theme.colorPrimary};

            @media (min-width: ${(props) => props.theme.sm}) {
                margin-right: 2rem;
            }

            @media (min-width: 1422px) {
                margin-right: 3rem;
            }

            .image-container {
                @media (min-width: ${(props) => props.theme.sm}) {
                    height: 25vw;
                }

                @media (min-width: ${(props) => props.theme.md}) {
                    height: 20vw;
                }

                @media (min-width: 1422px) {
                    height: 10rem;
                }
            }

            .preview-category {
                font-size: 0.777rem;
                font-family: 'SawtonBauhaus';
                margin-top: 0.5rem;
            }

            h3 {
                padding-top: 0;
                font-family: 'SawtonBauhaus';
                margin-bottom: 0.5rem;
                font-size: 1.222rem;
                line-height: 1.2;
            }

            p {
                font-size: 0.888rem;
            }
        }
    }

    .carousel__dot-group  {
        margin-bottom: 1rem;

        @media (min-width: 1422px) {
            display: none;
        }

        .carousel__dot {
            padding: 0.333rem;
            border-radius: 50%;
            border: 0;
            background-color: ${(props) => props.theme.colorPrimary};
            margin-right: 0.5rem;

            &:not(.carousel__dot--selected) {
                opacity: 0.5;
            }
        }
    }

    .carousel__first-button {
        display: none;
    }
`
