import React, { useEffect, useRef, useState } from 'react'
import { VimeoPlayer } from './vimeoPlayer'
import { YouTubePlayer } from './youTubePlayer'

export function WebVideo({
    type,
    videoId,
    thumbnail,
    figcaption,
}: {
    type: 'vimeo' | 'youtube'
    videoId: string
    thumbnail?: string
    figcaption?: string | JSX.Element
}) {
    if (type === 'vimeo') {
        return (
            <VimeoPlayer
                vimeoId={videoId}
                thumbnail={thumbnail}
                figcaption={figcaption}
            />
        )
    } else if (type === 'youtube') {
        return <YouTubePlayer videoId={videoId} figcaption={figcaption} />
    }
}
