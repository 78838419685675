import React, { useEffect, useRef, useState } from 'react'
import YouTube from 'react-youtube'

export function YouTubePlayer({
    videoId,
    figcaption,
}: {
    videoId: string
    figcaption?: string | JSX.Element
}) {
    return (
        <>
            <figure className='video youtube'>
                <YouTube videoId={videoId} />
            </figure>
            {figcaption && <div className="figcaption">{figcaption}</div>}
        </>
    )
}
