import React from 'react'
import styled from 'styled-components'

import { shorten } from '../../utils/shorten'

interface ArticleProps {
    article: {
        link: string
        image: string
        category: string
        title: string
        text: string
    }
}

export default function ArticlePreview({ article }: ArticleProps) {
    return (
        <Container href={article.link} className="article-preview">
            {article.image && (
                <div className="image-container">
                    <img src={article.image} alt="" />
                </div>
            )}
            <div className="preview-category">{article.category}</div>
            <h3 className="h4">{article.title}</h3>
            <p className="extract">{shorten(article.text, 120)} ...</p>
        </Container>
    )
}

const Container = styled.a`
    display: block;
    color: ${(props) => props.theme.colorWhite};
    line-height: 1.5;

    &:hover {
        text-decoration: none;
    }

    .image-container {
        position: relative;
        height: 50vw;

        @media (min-width: ${(props) => props.theme.md}) {
            height: 10rem;
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: ${(props) => props.theme.colorPrimary};
            opacity: 0;
            transition: opacity 0.4s ease-in;
        }

        &:hover {
            &::after {
                opacity: 0.25;
            }
        }

        img {
            width: 100%;
            height: inherit;
            object-fit: cover;
        }
    }

    .preview-category {
        font-size: 0.777rem;
        font-family: 'SawtonBauhaus';
        margin-top: 0.5rem;
    }

    h3 {
        padding-top: 0;
        font-family: 'SawtonBauhaus';
        margin-bottom: 0.5rem;
        font-size: 1.222rem;
        line-height: 1.2;
    }

    p {
        font-size: 0.888rem;
    }
`
